.burger-icon {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.burger-icon:before,
.burger-icon:after,
.burger-icon .middle-line {
  content: "";
  position: absolute;
  width: 44px;
  height: 4px;
  background-color: #fff;
  transition: transform 0.3s ease;
  transform-origin: right center;
}

.middle-line {
  display: none;
}

.burger-icon:before {
  top: 0;
}

.burger-icon:after {
  bottom: 0;
}

#burger-toggle {
  display: none;
}

#burger-toggle:checked {
  transform: translateX(0);
}

#burger-toggle:checked ~ .burger-icon:before {
  transform: rotate(-21deg);
}

#burger-toggle:checked ~ .burger-icon:after {
  transform: rotate(21deg);
}

@media screen and (min-width: 768px) {
  .burger-icon:before,
  .burger-icon:after,
  .burger-icon .middle-line {
    width: 60px;
    height: 5px;
    transform-origin: center center;
  }

  .burger-icon .middle-line {
    display: inline-block;
    top: 28px;
  }

  #burger-toggle:checked ~ .burger-icon:before {
    transform: rotate(45deg);
    top: 28px;
  }

  #burger-toggle:checked ~ .burger-icon:after {
    transform: rotate(-45deg);
    bottom: 28px;
  }

  #burger-toggle:checked ~ .burger-icon .middle-line {
    opacity: 0;
  }
}
